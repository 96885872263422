import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'
import store from "@/store/index";

const routes: Array<RouteRecordRaw> = [

  {
    path: "/",
    name: "App",
    component: () =>
        import(/* webpackChunkName: "base" */ "../layouts/NavBarLayout.vue"),
    children: [
      {
        path: "/",
        name: 'Home',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "base" */ '../views/CheckingPage.vue'),
      },
      /* Courses Start */
      {
        path: "/:client/redirect/",
        name: 'NMCheckingPage',
        meta: { requiresAuth: false },
        component: () => import(/* webpackChunkName: "base" */ '../views/user/nm/NMCheckingPage.vue'),
      },
      {
        path: "/courses/",
        name: 'CoursesListPage',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "base" */ '../views/user/course/CoursesListPage.vue'),
      },
      {
        path: "/course/:courseId",
        name: 'CourseDetailsPage',
        meta: { requiresAuth: true },
        props: true,
        component: () => import(/* webpackChunkName: "base" */ '../views/user/course/CourseDetailsPage.vue'),
      },
      /* Courses End */
      /* Admin */
      {
        path: "/admin/courses/",
        name: 'AdminCoursesListPage',
        meta: { requiresAuth: true },
        component: () => import(/* webpackChunkName: "base" */ '../views/admin/course/AdminCoursesListPage.vue'),
      },
      {
        path: "/admin/course/:courseId",
        name: 'AdminCourseDetailsPage',
        meta: { requiresAuth: true },
        props: true,
        component: () => import(/* webpackChunkName: "base" */ '../views/admin/course/AdminCourseDetails.vue'),
      },
      {
        path: "/admin/analytics",
        name: 'AdminAnalyticsPage',
        meta: { requiresAuth: true },
        props: true,
        component: () => import(/* webpackChunkName: "base" */ '../views/admin/analytics/AdminAnalyticsPage.vue'),
      },
      /* Admin End */
    ],
  },
  {
    path: "/",
    name: "NoAuth",
    component: () =>
    import(/* webpackChunkName: "base" */ "../layouts/NoNavBarLayout.vue"),
    children: [
      {
        path: '/login',
        name: 'Login',
        meta: { isLoginPage: true },
        component: () => import(/* webpackChunkName: "login" */ '../views/auth/LoginPage.vue')
      },
      {
        path: '/forgot-username',
        name: 'ForgotUsername',
        meta: { isLoginPage: true },
        component: () => import(/* webpackChunkName: "login" */ '../views/auth/ForgotUsernamePage.vue')
      },
      {
        path: '/forgot-password',
        name: 'ForgotPassword',
        meta: { isLoginPage: true },
        component: () => import(/* webpackChunkName: "login" */ '../views/auth/ForgotPasswordPage.vue')
      },
    ],
  },
  {
    // path: "*",
    path: "/:catchAll(.*)",
    name: "NotFound",
    redirect: "/",
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

router.beforeEach((to, from, next) => {
  if (to.meta.requiresAuth && !store.state.isLoggedIn) next({ name: "Login" });
  else if (to.meta.isLoginPage && store.state.isLoggedIn)
    next({ name: "Home" });
  else {
    let metaRole = to.meta.role;
    if(!metaRole) {
      next();
    } else {
      // @ts-ignore
      const role = store.state.activeUser["account_role"];
      let roleCheck = "";
      if (role === 1 || role === 2 || role === 4 || role === 5) {
        roleCheck = "admin";
      }
      if (role === 3) {
        roleCheck = "coordinator";
      }
      if (role === 6 || role === 7) {
        roleCheck = "college";
      }
      if (role === 8) {
        roleCheck = "student"
      }
      if (role === 10) {
        roleCheck = "da"
      }
      if (role === 11) {
        roleCheck = "placements"
      }
      // @ts-ignore
      if (metaRole.indexOf(roleCheck) === -1) {
        next({name: "Home"});
      } else {
        next();
      }
    }
  }
});
export default router
