import { createStore } from "vuex";
import authStore from "@/store/auth/authStore";
import profileStore from "@/store/profile/profileStore";
import coursesStore from "@/store/courses/coursesStore";
import adminCoursesStore from "@/store/admin/adminCoursesStore";
import adminAnalyticsStore from "@/store/admin/adminAnalyticsStore";
import axios from "@/http/http";

import router from "@/router";
import { baseUrl } from "@/http/global_urls";

export default createStore({
  state: {
    isLoggedIn: false,
    isLoading: false,
    activeUser: {},
  },
  mutations: {
    SET_LOGGED_IN(state, value) {
      state.isLoggedIn = value;
    },
    SET_LOADING(state, value) {
      state.isLoading = value;
    },
    SET_USER_DATA(state) {
      try {
        const user = localStorage.getItem("user");
        state.activeUser = JSON.parse(user || "{}");
      } catch (e) {
        state.activeUser = {};
      }
    },
  },
  getters: {
    isLoggedIn(state) {
      return state.isLoggedIn;
    },
    isLoading(state) {
      return state.isLoading;
    },
    activeUser(state) {
      return state.activeUser;
    },
  },
  actions: {
    checkLoginStatus({ commit, dispatch }) {
      commit("SET_LOGGED_IN", true);
      dispatch("fetchProfileDetails");
    },
    logout({ commit }, data) {
      return new Promise(async (resolve, reject) => {
        let redirectUrl = "";
        try {
          console.info("Logging out", baseUrl);
          let { data: response } = await axios.post("/api/v1/logout/")
          redirectUrl = response.redirectUrl;
        } catch (e) {
          console.log(e);
        } finally {
          localStorage.clear();
          commit("SET_LOGGED_IN", false);
          commit("SET_USER_DATA");
          if(!data) {
            router.push("/");
            commit("SET_LOADING", false);
          }
          resolve(null);
          if (redirectUrl !== "") {
            window.location.href = redirectUrl;
          }
        }
      });
    },
  },
  modules: {
    authStore,
    profileStore,
    coursesStore,
    adminCoursesStore,
    adminAnalyticsStore
  },
});
