import axios from "@/http/auth";
import { Module, ActionTree } from "vuex";

const actions: ActionTree<any, any> = {
  performLogin({ commit, dispatch }, loginDetails: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(loginDetails).forEach((key: string) => {
        formData.append(key, loginDetails[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post("/api/v1/login/", formData);
        commit("SET_LOGGED_IN", true);
        commit("SET_LOADING", false);
        const json = response.data;
        commit("saveToken", json.access);
        commit("saveRefreshToken", json.refresh);
        // commit("saveUser", JSON.stringify(json.data));
        //
        // commit("SET_USER_DATA");
        dispatch("fetchProfileDetails");
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message || err.response.data.detail);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  loginViaSSO({ commit, dispatch }, loginDetails: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      const { client, ...rest } = loginDetails;

      Object.keys(rest).forEach((key: string) => {
        formData.append(key, rest[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post(`/api/v1/token/validation/${client}/`, formData);
        commit("SET_LOGGED_IN", true);
        commit("SET_LOADING", false);
        const json = response.data;
        commit("saveToken", json.access);
        commit("saveRefreshToken", json.refresh);
        dispatch("fetchProfileDetails");
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.error || err.response.data.message || err.response.data.detail);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  authSendUsernameToEmail({ commit, dispatch }, loginDetails: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(loginDetails).forEach((key: string) => {
        formData.append(key, loginDetails[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post("/api/v1/forgot/username/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message || err.response.data.detail);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  authForgotPasswordStep1({ commit, dispatch }, loginDetails: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(loginDetails).forEach((key: string) => {
        formData.append(key, loginDetails[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post("/api/v1/forgot/password/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message || err.response.data.detail);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  authForgotPasswordStep2({ commit, dispatch }, loginDetails: any) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      Object.keys(loginDetails).forEach((key: string) => {
        formData.append(key, loginDetails[key]);
      });

      commit("SET_LOADING", true);
      try {
        const response = await axios.post("/api/v1/forgot/password/reset/", formData);
        commit("SET_LOADING", false);
        resolve(response.data);
      } catch (err: any) {
        commit("SET_LOADING", false);
        try {
          reject(err.response.data.message || err.response.data.detail);
        } catch (e) {
          console.error(e);
          reject(err.message);
        }
      }
    });
  },
  tokenRefresh({ commit, dispatch }) {
    // eslint-disable-next-line no-async-promise-executor
    return new Promise(async (resolve, reject) => {
      const formData = new FormData();

      const refreshToken = localStorage.getItem("refresh");
      if (refreshToken) {
        formData.append("refresh", refreshToken);
      }
      try {
        const response = await axios.post("/api/v1/token/refresh/", formData, { skipAuthRefresh: true } as any);
        commit("SET_LOGGED_IN", true);
        const json = response.data;
        commit("saveToken", json.access);
        resolve(response.data);
      } catch (err: any) {
        dispatch("logout");
        try {
          reject(err.response.data.message || err.response.data.detail);
        } catch (e) {
          reject(err.message);
        }
      }
    });
  },
};
const authStore: Module<any, any> = {
  state: () => ({}),
  mutations: {
    saveToken(state, token) {
      localStorage.setItem("token", token);
      this.commit("SET_LOGGED_IN", true);
    },
    saveRefreshToken(state, token) {
      localStorage.setItem("refresh", token);
    },
  },
  actions,
  getters: {},
};

export default authStore;
